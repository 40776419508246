import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Share from '../../../components/Share';
import ContactForm from '../../../components/ContactForm';
import Checkout from "../../../components/Checkout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <Row mdxType="Row">
        <Column colMd={1} colLg={1} mdxType="Column">
        </Column>
        <Column colMd={6} colLg={10} mdxType="Column">
          <p>{`WiSE Indicator for NinjaTrader 8`}</p>
          <p>{`Unlock the power of price action trading with the `}<strong parentName="p">{`WiSE (Wizdough Second Entry) Indicator`}</strong>{`, a groundbreaking tool designed for traders using the NinjaTrader 8 platform. The WiSE Indicator identifies over 16 different price action signals, such as second entries, higher lows, lower highs, and failed second entries, enabling you to spot the perfect entry points for your trades.`}</p>
          <p>{`Built on Mack’s adaptation of Al Brooks’ original technique, the WiSE Indicator is optimized for any bar type and timeframe, though it particularly excels on ES 2000 ticks charts with a 21-period Exponential Moving Average (EMA).`}</p>
        </Column>
        <br />
      </Row>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={3} colLg={5} mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          width: "100%",
          padding: "1rem 10%"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAC4jAAAuIwF4pT92AAADwUlEQVQ4y31Va4/bRBT1H+ADAi3sJn7MePx2bG8S59HEm02ySTbZ97YFFipaIRWoKkBbqiLxkT9+OON9dFtBPxx5POM5c8+9544NNwjg9XpQnQ5U2YMUAt54DG80gj+dwo0i6G/02J/PodIU0nHguu5/wvD39xE+ewZ/taqfqijgVdUN4WJRE3qTCYKzM4SXlzWp63lw5f8Q2kUCf7mCynP4h4dwwxDSshE8eQKv24PwLR424/tT+EdH8GazOmKtRJJUSvkBjCJNEOgILi7g7e7WcjSCkxOoLIPTNGHzsOD4CP56zbSU95JrQh2ZvIF+N178eI43TzeYkXjHth98IGEz0mHVx3rQRcN2oHy/JhPuLdEtpCfhqlvJQTvHi8EA3zA6hySxDl0puIKEjsT89QJ/H29QpS1YdWQKkUsCR3CdsAQsz4bpOnA4NhxORiT4lQU4ThIobrJNRsG5s4s5Rs8n6KoQ14M+trmmOB9wY6/fRreTo5qUWK4mOFtMMT4Yw1AMVcsZMl9vl0uklF6UbTx/eYqrV2tIysyYgnck3GFkumBxlePb79b45+cTXP1yjoPlPt5ebvDTb6cwdGUUtWs5Ff3413qDx39usFlOoEKfMhgtv3lHH5Z5BtFgGl7O8GhU4Pr7BdqzHuymQKgPpmOMu3Jr0h3XxJSkq8MKXzWaUIxMK9hhWqZxjDezOSbnFf54fYy4TXdI5lqKuhj6UFs5MB6aUmpJnoKngvdztwaWTMsPB1NUx3OEw5INkMMtduHyIFeI98b+2Ok66RofzqvaLg67yO0NIEkiA/+mLelLDa2yJhRkf4i7FNzP6TH96WU5suk+8jhBGKdI2NNZlKIVRsj7QwTDRxCOTR+yjT4JnxcD21EMR/Biic/OGlj7DnLhodGRcFIFJ2FD7HUQpAWMVquFTyGhN9tlF6etDJFj4rT8Er/LLbQtiYOkgZlnonK+hnVhItsbULJupY/g3I0p2bGsuqdbOkoafmVvIxk7CKMM6/EeTnhZ9L0Un199AWuesVOYozsItpsUN8/7eU3MIqh+H5Hu5SRGkAXIUoG8kyBvt5Ezv6pqQfR2YcSMIiISSpDdJsyyCXu0jYj+ultTJG7xfux2u5hUe0jCAOOIeeTV1mQalC2QVWP4ZQBDb1hZJhZNC1Fo45WzhTCx0NKHECUjvN7aYkFipLzmsrxAEQcYhRY6fhORbcJtdxD2O/CjBi/YB5Ilb5eRadMmN+96TZt2RNvo1lQsUjShdYZDFNJGntKH/QFc/j4Eu0nv+xe/Jl4lRqylywAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "chart-example",
              "title": "chart-example",
              "src": "/static/6de09ebef5b350a3bbd931a691ed22cc/36a81/se-chart.png",
              "srcSet": ["/static/6de09ebef5b350a3bbd931a691ed22cc/7fc1e/se-chart.png 288w", "/static/6de09ebef5b350a3bbd931a691ed22cc/36a81/se-chart.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </div>
      </Column>
      <Column colMd={3} colLg={5} mdxType="Column">
        <div style={{
          space: "0"
        }}>
One license per machine:
          <br />
          <Checkout id="2" lnk="https://buy.stripe.com/7sIaFw7OtaKy1LG7sx" mdxType="Checkout"><div style={{
              fontSize: "1.5rem"
            }}> $20 / month</div><s style={{
              color: '#999999'
            }}>&nbsp;$25 / month&nbsp;</s><br />billed annually</Checkout>
          <br />
          <br />
          <Checkout id="2" lnk="https://buy.stripe.com/dR6fZQ4Ch8Cq8a4aEK" mdxType="Checkout"><div style={{
              fontSize: "1.5rem"
            }}> $25 / month</div><s style={{
              color: '#999999'
            }}>&nbsp;$30 / month&nbsp;</s><br />billed monthly</Checkout>
          <br />
          <br />
          <a href="https://billing.stripe.com/p/login/dR65of0AH3SrcUgbII">Manage Your Subscription</a>
          <br />
          <br />
          <p>{`By purchasing from this website, you acknowledge that you understand and accept the following agreements:`}</p>
          <ul>
            <li parentName="ul">{`No Refunds Policy;`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.wizdough.com/terms/"
              }}>{`Terms and Conditions`}</a>{`;`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.wizdough.com/risk/"
              }}>{`Full Risk Disclosure`}</a>{`;`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.wizdough.com/software/"
              }}>{`Software Agreement`}</a>{`.`}<br />
              <br />
              <br /></li>
          </ul>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#343444',
            padding: '1rem',
            width: '100%'
          }}>
  <a href="https://drive.google.com/uc?id=13XVkd0Tgx-YXrzdEl1sSTPYZyK0lIPl_&export=download" style={{
              color: '#fff',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center'
            }}>
    <div style={{
                width: '30px',
                marginRight: '1rem'
              }}>
      <img src="/images/download-blue.png" alt="download-blue" title="download-blue" style={{
                  width: '100%'
                }} />
    </div>
    <div>
      Download - First time installed will work as a trial for 7 days.
    </div>
  </a>
          </div>
          <br />
          <div style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#343444',
            padding: '1rem',
            width: '100%'
          }}>
    <div style={{
              width: '30px',
              marginRight: '1rem'
            }}>
      <img src="/images/document-blue.png" alt="download-blue" title="download-blue" style={{
                width: '100%'
              }} />
    </div>
    <div>
              <a rel="noopener noreferrer" target="_blank" href="https://docs.google.com/document/d/1ALwRNtluqm_mdLRnB1abUunhYMgAIxHSGRfHIXim3CE">Read and Comment on Full Documentation (Google Docs)</a>
              <br />
              <a rel="noopener noreferrer" target="_blank" href="https://docs.google.com/document/d/1ALwRNtluqm_mdLRnB1abUunhYMgAIxHSGRfHIXim3CE/export?format=pdf">Download Full Documentation (PDF)</a>
    </div>
          </div>
        </div>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={4} colLg={7} mdxType="Column">
        <h2>{`Key Features`}</h2>
        <ul>
          <li parentName="ul">{`Highly customizable to suit your unique trading style`}</li>
          <li parentName="ul">{`Minimalist or comprehensive display options`}</li>
          <li parentName="ul">{`Flexible alerts for various trading scenarios`}</li>
          <li parentName="ul">{`Variety of filters for ultimate control`}</li>
          <li parentName="ul">{`Lightweight and user-friendly for traders of all levels`}</li>
          <li parentName="ul">{`Fast performance for real-time analysis and backtesting`}</li>
          <li parentName="ul">{`Supports 3rd party strategies`}</li>
        </ul>
      </Column>
      <Column colMd={3} colLg={3} mdxType="Column">
        <h3>{`Video Manual`}</h3>
        <div style={{
          justifyContent: 'flex-end',
          display: "flex"
        }}>
          <br />
          <iframe id="ytplayer" type="text/html" src="https://www.youtube.com/embed/oU55hfdgxPo?playsinline=1" frameBorder="0" allowFullScreen />
        </div>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={6} colLg={10} mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          padding: '1rem',
          width: "100%"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "33.68055555555555%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABYElEQVQoz02R227jMAxE/R9NYkvWXbLjSxoHRdOku8Bu+7JP+/+/cko7QNGHwVAURc5QlXeGHB2NbtHtA0prmkajhY0137ntXuqU0hu37QqzcQiBvu+p/v9e+Pe58Hc5YqXIeUtJjlOJ5LFwPw84yY9zoRiLMYaUPd5ZVKOkuXoMEwzDQHWSwlg8H5eR9/OR56Xnz3Xi/jIyTHInDa7LwFr32idubxNnqft1GbjOPbf7zPvUUbrE2/1GVa9TxF59aHh97siiblXxVKuHbbHjNjWaPgd8kPXImyCrGuQcoucYA9PYMZ9OVKUv5A0yJQt3nSjOnHNiTAFtLa08NsJaBmmJ7RoLlOx3Ha5Ni3GO4L00dBpna1La49KBTnaS25qL2YmKA1bVBLMn1DVR4mT3tE3D6J7IbsdRHRjVTlzo7WOqvogiH4jJEzpHljiF1cpq7xH/RBbEGJmDp/zMx4QXhV9uu87atEAC3wAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "long-chart",
              "title": "long-chart",
              "src": "/static/a2c962e45cd72f63d400b0afb510eebd/3cbba/long-chart.png",
              "srcSet": ["/static/a2c962e45cd72f63d400b0afb510eebd/7fc1e/long-chart.png 288w", "/static/a2c962e45cd72f63d400b0afb510eebd/a5df1/long-chart.png 576w", "/static/a2c962e45cd72f63d400b0afb510eebd/3cbba/long-chart.png 1152w", "/static/a2c962e45cd72f63d400b0afb510eebd/0b124/long-chart.png 1728w", "/static/a2c962e45cd72f63d400b0afb510eebd/4ea69/long-chart.png 2304w", "/static/a2c962e45cd72f63d400b0afb510eebd/66381/long-chart.png 2536w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </div>
        <h2>{`Customize Your Trading Experience`}</h2>
        <p>{`The WiSE Indicator offers an extensive range of settings and filters, allowing you to tailor the tool to your specific needs. Choose from a selection of symbols and colors to represent different signals, and enable or disable alerts as you see fit.`}</p>
        <p>{`Whether you’re a new trader looking to learn the ropes or a seasoned pro seeking a streamlined experience, the WiSE Indicator can be configured to display as much or as little information as you need.`}</p>
        <h2>{`Harness the Power of Price Action`}</h2>
        <p>{`With its advanced algorithms and innovative features, the WiSE Indicator makes it easy to identify potential entry points based on price action patterns. Spot second entries, lower highs, and higher lows with ease, and make more informed trading decisions in real-time.`}</p>
        <div style={{
          backgroundColor: '#343444',
          padding: '1rem',
          width: "40%"
        }}>
          <img {...{
            "src": "/8018d774ee917bdf23fe7eec1c2c2126/second-entry.svg",
            "alt": "second-entry",
            "title": "second-entry"
          }}></img>
        </div>
        <p>{`The WiSE Indicator is also designed for seamless integration with automated strategies and algo-trading, making it the ultimate tool for today’s modern trader.`}</p>
        <h2>{`Strategy Use`}</h2>
        <p>{`The WiSE Indicator is designed to be used as a standalone tool or in conjunction with other indicators and strategies. It can be used to identify potential entry points, or as a filter to confirm signals from other indicators.`}</p>
        <p>{`Please watch this video to learn how to use the WiSE Indicator with other strategies:`}</p>
        <iframe id="ytplayer" type="text/html" src="https://www.youtube.com/embed/b13FkEQqPw0?playsinline=1" frameBorder="0" allowFullScreen />
        <div>You can download the sample strategy used in the video here: <a href="https://drive.google.com/uc?id=1fpieJtgvXLCqIxjoB3HJCM6xtLtWFX-6&export=download" style={{
            color: '#fff',
            textDecoration: 'none'
          }}>
Sample ATM Strategy.
          </a></div>
        <h2>{`Get Started Today`}</h2>
        <p>{`Elevate your trading game with the WiSE Indicator for NinjaTrader 8. Discover the difference that expert price action analysis can make, and unlock your full trading potential.`}</p>
        <p>{`Don’t miss out on this essential tool for successful trading – `}<strong parentName="p"><a parentName="strong" {...{
              "href": "#"
            }}>{`Get the WiSE Indicator now!`}</a></strong></p>
        <Share mdxType="Share" />
        <h3>{`Feedback`}</h3>
        <hr></hr>
      </Column>
    </Row>
    <br /><br />
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={3} colLg={6} mdxType="Column">
        <p>{`Do you have questions or feedback?`}</p>
        <p>{`Need technology or a new concept for your business? `}</p>
        <p>{`Contact us, and our team will get back to you.`}</p>
      </Column>
      <Column colMd={3} colLg={4} mdxType="Column">
Please fill out the form below, and let us discuss your idea. Your details will be kept confidential.
        <br />
        <ContactForm mdxType="ContactForm" />
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      